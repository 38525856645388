import { gql } from '@apollo/client';

export const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
    }
  }
`;

export const REGISTER = gql`
  mutation Register($data: RegisterData!) {
    register(data: $data) {
      id
      token
    }
  }
`;

export const INHERIT_CATALOG = gql`
  mutation InheritCatalog($token: ID!) {
    inheritCatalog(token: $token) {
      id
    }
  }
`;

export const SELECT_WORKSPACE = gql`
  mutation SelectWorkspace($id: ID!) {
    selectWorkspace(id: $id) {
        id
    }
  }
`;
